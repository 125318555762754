<div class="filtertag_menu" :class="{mobile: mobile}" v-if="isEnabled">
    <div class="container">
        <div class="tagitem" v-for="filter in filterStore.serviceFilters">          
            <span>{{ $t('header.services.' + filter.name) }}</span>
            <span class="tagclose fa-solid fa-xmark" @click="removeServiceFilter(filter.name)"></span>
        </div>
        <div class="tagitem" v-for="filter in filterStore.ageFilters.filter(p => p.enabled)">
            <span>{{ $t('header.filters.ages.' + filter.title) }}</span>
            <span class="tagclose fa-solid fa-xmark" @click="removeAgeFilter(filter)"></span>
        </div>
        <div class="tagitem" v-for="filter in filterStore.genderFilters">
            <span>{{ $t('header.filters.genders.' + filter.name) }}</span>
            <span class="tagclose fa-solid fa-xmark" @click="removeGenderFilter(filter.name)"></span>
        </div>
        <div class="tagitem" v-for="filter in filterStore.sexualOrientationFilters">
            <span>{{ $t('header.filters.sexualOrientations.' + filter.name) }}</span>
            <span class="tagclose fa-solid fa-xmark" @click="removeSexualOrientationFilter(filter.name)"></span>
        </div>
    </div>
</div>