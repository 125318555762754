<script lang="ts" setup>
import { useFilterStore, type ServiceType, GenderType, SexualOrientationType } from '@/stores/filter';
import { useUserStore } from '@/stores/user';
import { computed, ref } from 'vue';

const { toggleFilterMenu } = useUserStore();
const filterStore = useFilterStore();

const props = defineProps<{
    mobile?: boolean;
}>();

const toggleAgeFilter = (filter: any) => {
    filterStore.toggleAgeFilter(filter);
};

const toggleServiceFilter = (service: string) => {
    filterStore.toggleServiceFilter({ name: service as ServiceType });
};

const isServiceFilterActive = (service: ServiceType) => {
    return filterStore.isServiceFilterActive(service);
};

const toggleGenderFilter = (gender: string) => {
    filterStore.toggleGenderFilter({ name: gender as GenderType });
};

const isGenderFilterActive = (gender: string) => {
    return filterStore.isGenderFilterActive(gender as GenderType);
};

const toggleSexualOrientationFilter = (sexualOrientation: string) => {
    filterStore.toggleSexualOrientationFilter({ name: sexualOrientation as SexualOrientationType});
};

const isSexualOrientationFilterActive = (sexualOrientation: string) => {
    return filterStore.isSexualOrientationFilterActive(sexualOrientation as SexualOrientationType);
};

const theme = ref(import.meta.env.VITE_Themename);
const isDusk = computed(() => theme.value === 'dusk');
</script>

<template>
    <div :class="['filters', { mobile }]">
        <div class="filters__dropdown">
            <h2>{{ $t('header.filters.services') }}</h2>
            <span 
                v-for="service in filterStore.availableServices" 
                :key="service" 
                class="filters__dropdown-item" 
                :class="{ active: isServiceFilterActive(service) }" 
                @click="() => toggleServiceFilter(service)"
            >
                {{ $t('header.services.' + service) }} 
                <i :class="'se se-' + service"></i>
            </span>
        </div>
        <div class="filters__dropdown">
            <h2>{{ $t('header.filters.age') }}</h2>
            <span 
                v-for="ageFilter in filterStore.ageFilters" 
                :key="ageFilter.title" 
                class="filters__dropdown-item" 
                :class="{ active: ageFilter.enabled }" 
                @click="() => toggleAgeFilter(ageFilter)"
            >
                {{ $t('header.filters.ages.' + ageFilter.title) }}
            </span>        
        </div>
        <div v-if="isDusk" class="filters__dropdown">
            <h2>{{ $t('header.filters.gender') }}</h2>
            <span 
                v-for="gender in filterStore.availableGenders"
                :key="gender"
                class="filters__dropdown-item"
                :class="{ active: isGenderFilterActive(gender) }"
                @click="() => toggleGenderFilter(gender)"
            >
                {{ $t('header.filters.genders.' + gender) }}
            </span>
        </div>
        <div v-if="isDusk" class="filters__dropdown">
            <h2>{{ $t('header.filters.sexualOrientation') }}</h2>
            <span 
                v-for="sexualOrientation in filterStore.availableSexualOrientations"
                :key="sexualOrientation"
                class="filters__dropdown-item"
                :class="{ active: isSexualOrientationFilterActive(sexualOrientation) }"
                @click="() => toggleSexualOrientationFilter(sexualOrientation)"
            >
                {{ $t('header.filters.sexualOrientations.' + sexualOrientation) }}
            </span>
        </div>
        <div v-if="mobile" class="filters__btn">
            <a class="btn btn-full small" @click="toggleFilterMenu">{{ $t('header.filters.apply') }}</a>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '../../../styles/_mixins.scss';

.filters {
    display: flex;
    flex-direction: row;
    background: $header-user-menu-bgcolor;
    vertical-align: top;

    &.mobile {
        height: 100%;
        flex-direction: column;

        .filters__dropdown {
            width: 100%;
        }

        .filters__dropdown h2 {
            margin-top: 20px;
            border-top: 1px solid rgba(0,0,0,0.20);
        }
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 60%;
        border-right: 1px solid rgba(255,255,255,0.05);

        &:last-child { 
            border: none; 
            width: 40%; 
        }

        body.dusk & {
            &:nth-child(1) {
                width: 45%;
            }
            &:nth-child(2),
            &:nth-child(3) {
                width: 27.5%;
            }
        }

        h2 {
            position: relative;
            color: $header-bottom-filters-age-title-color;
            @include rem(font-size, $header-bottom-filters-age-title-size);
            @include rem(padding, 10px 15px);
            margin: 0;
            line-height: 170%;
            border-bottom: 1px solid rgba(255,255,255,0.05);
        }

        &-item {
            @include rem(height, $header-bottom-filters-drop-height);
            line-height: $header-bottom-filters-drop-height;
            @include rem(font-size, $header-bottom-filters-drop-size);
            color: $header-bottom-filters-drop-color;
            cursor: pointer;
            @include rem(padding, 0px 0px 0px 15px);

            &:before {
                font-family: "Font Awesome 6 Free";
                font-weight: 400 !important;
                content: "\f0c8";
                @include rem(margin-right, 10px);
            }

            &.active:before {
                font-weight: 900 !important;
                content: "\f14a";
                color: $header-bottom-filters-drop-acolor;
            }

            i { 
                float: right;
                width: 40px;
                opacity: 0.50;
                @include rem(height, $header-bottom-filters-drop-height);
                text-align: center;
                font-weight: 900;

                &.se-cam:before,
                &.se-peek:before,
                &.se-phone:before {
                    width: 100%;
                }
                &.se-toy:before, 
                &.se-videocall:before, 
                &.se-voyeur:before {
                    filter: invert(0.4);
                    transform: scale(0.4);

                    body.dusk & {
                        filter: invert(1);
                    }
                }
            }
        }
    }

    &__btn {
        display: flex;
        @include rem(margin, 10px 0px);
        @include rem(padding, 0px 15px);
        width: calc(100% - 30px);
    }
}
</style>