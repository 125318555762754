import type { PaymentProcessor } from '../../ontology/purchaseOptions';
import { get, post } from '../fetch-extension';
import filters from '@/filters';
/*
Submits your purchases:
 - method: the payment providers you have selected
 - amount: the amount purchased in credits
 - promo: the entered promo code
*/
export async function submitPayment(method: string, amount: number, promo: string | number = '', issuer?: string) {
    return get<PaymentProcessor>(`client/buy/${method}/${amount}`, {
        query: {
            promo: promo,
            exchangePrice: filters.exchangeAmount(amount),
            issuer: issuer
        }
    });
}


/*
Gets curo banking list
*/
export async function getCuroIssuers() {
    return get<any>('client/curo/issuers/ideal');
}

/*
Finish paysafe payment:
 - amount: the amount purchased in credits
 - paymentHandleToke: the purchase token when from paysafe
 - keyword: Keywords of the packages that are bought
*/

export async function finishPaySafePayment(payload: any) {
    payload.exchangePrice = filters.exchangeAmount(payload.amount);
    const { error, result } = await post<any>(`client/paysafe/payment`, {
        body: payload
    });

    if (error) {
        if (error.code && error.code === 504) {
            error.message = 'modals.paysafe.paymentfailed';
        }

        return { error };
    }

    return { result };
}