<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue';
import router from '@/router';
import { useFilterStore, type ServiceType } from '@/stores/filter';

const props = defineProps<{
    mobile?: boolean;
}>();

const filterStore = useFilterStore();


const isEnabled = computed(() => {
    const currentRoute = router.currentRoute.value;
    const ageFilters = [...filterStore.ageFilters].filter(p => p.enabled);
    const serviceFilters = [...filterStore.serviceFilters];    
    const genderFilters = filterStore.genderFilters;
    const sexualOrientationFilters = filterStore.sexualOrientationFilters;
    // Only show tags when on grid and at least one filter is active
    return (
        (ageFilters.length > 0 || serviceFilters.length > 0 || genderFilters.length > 0 || sexualOrientationFilters.length > 0) &&
        currentRoute?.name === 'grid'
    );
})

function removeServiceFilter(filter: string) {
    filterStore.removeServiceFilter({ name: filter });
    filterStore.applyFilter();
}

function removeAgeFilter(ageFilter: any) {
    filterStore.toggleAgeFilter(ageFilter);
     filterStore.applyFilter();
}

function removeGenderFilter(gender: string) {
    filterStore.removeGenderFilter({ name: gender });
    filterStore.applyFilter();
}

function removeSexualOrientationFilter(sexualOrientation: string) {
    filterStore.removeSexualOrientationFilter({ name: sexualOrientation});
    filterStore.applyFilter();
}
</script>

<template src="./filtertag.html"></template>

<style lang="scss">
@import './filtertag.scss';
</style>